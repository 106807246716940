import { css } from 'styled-components'

import webBold from './assets/fonts/ObjektivMk3_W_Bd.woff2'
import webMedium from './assets/fonts/ObjektivMk3_W_Md.woff2'
import webRegular from './assets/fonts/ObjektivMk3_W_Rg.woff2'
import UKNumberPlateEot from './assets/fonts/uknumberplate/UKNumberPlate.eot'
import UKNumberPlateWoff2 from './assets/fonts/uknumberplate/UKNumberPlate.woff2'

const fonts = css`
  @font-face {
    font-family: 'Objektiv Mk3';
    src: url('${webRegular as never}') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Objektiv Mk3';
    src: url('${webMedium as never}') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Objektiv Mk3 Bold';
    src: url('${webBold as never}') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateEot as never}') format('eot'),
      url('${UKNumberPlateEot as never}') format('eot');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateWoff2 as never}') format('woff2'),
      url('${UKNumberPlateWoff2 as never}') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }
`

export default fonts
